import * as React from "react"
import styled from "styled-components"

const Container = styled.div`
    position: relative;
  display: flex;
  flex-direction: row;
  font-weight: 500;
  font-size: 16px;
  aspect-ratio: 1;
  line-height: 150%;
  width: ${p => `${(~~100/p.ancho)-2}%`};
  height:auto ;
  text-align: justify;
  
    border-radius: 50%;
      border: 10px solid #0044B0;;
      box-shadow: 0px 1px 7px 1px rgba(0, 0, 0, 0.15);
      border-style: double;
      object-fit: cover;

  svg {
    position: relative;

     }
.textInside{
  width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 120%;
    font-size: 14px;
    font-weight:700;
    color: #0044B0;
   
    

}
     @media (max-width: 1600px) {
      .textInside{
        font-size: 12px;
      }
     
     }

     @media (max-width: 1400px) {


.textInside{
  font-size: 14px;
}
margin-bottom:24px;
}

     @media (max-width: 600px) {

      width: 45%;
      .textInside{
        font-size: 14px;
      }
     margin-bottom:24px;
     }
`
export const Adjectives = ({ children ,cantidad}) => {
  return (
    <Container ancho={cantidad}>
      {/*<svg xmlns="http://www.w3.org/2000/svg" width="112" height="112" viewBox="0 0 112 112" fill="none">
      <circle cx="56" cy="56" r="55" stroke="#0044B0" stroke-width="2"/>
      <circle cx="56" cy="56" r="50" stroke="#0044B0" stroke-width="2"/>
  </svg>*/}

      <div className="textInside">
     {children}
     </div>
    </Container>
  )
}