import React, { useEffect, useState } from "react"
import styled from "styled-components"

import {getMainPage, getAboutUsPage, getGeneralInfo } from "../../providers"

import { AboutUs, Galery } from "../../components/AboutUs"
import { Hero4 } from "../../components/Hero4"
import { MainLayout } from "../../components/Layouts/MainLayout"

import "../../assets/css/global.css"

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
`
const About = () => {
  const [aboutUsPage, setAboutUsPage] = useState({})
  const [mainPage, setMainPage] = useState([])
  const [generalInfo, setGeneralInfo] = useState({})

  useEffect(() => {
    const fetchAboutUs = async () => {
      try {
        const _aboutUs = await getAboutUsPage()
        setAboutUsPage(_aboutUs[0])

        const _info = await getMainPage()
        setMainPage(_info[0])

        const _generalInfo = await getGeneralInfo()
        setGeneralInfo(_generalInfo[0])

      } catch (error) {
        console.log(error)
      }
    }
    fetchAboutUs()
  }, [])

  return (
    <MainLayout>
      <Hero4
        backgroundImage={aboutUsPage?.background_image}
        title={aboutUsPage?.title}
      />
      <Container>
        <AboutUs
          contentTitle={aboutUsPage?.content_title}
          content={aboutUsPage?.content}
          contentImage={aboutUsPage?.content_image}
          data={aboutUsPage}
          data2={aboutUsPage?.about_us_adjectives}
        />
        <Galery sliderImages={aboutUsPage?.about_us_images}
        counts={mainPage?.counts}
        data={aboutUsPage}
        dataGeneral={generalInfo?.whatsapp_link} />
      </Container>
    </MainLayout>
  )
}

export default About
