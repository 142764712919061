import styled from "styled-components"

export const Paragraph = styled.p`
  margin-top: 24px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  text-align: justify;
`
