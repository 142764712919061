import React from "react"
import styled from "styled-components"
import { Button02 } from "../Button02"

const Container = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  justify-content: center;
  display: flex;
  flex-direction: column;
`
const SecondBlock=styled.div`
position: relative;
display: flex;
justify-content: center;
width: 100%;
background-color: #0044B0;
margin-bottom: 3em;
@media (max-width: 600px) {
  margin-bottom: 0;

}
`
const SecondBlockChild= styled.div`
max-width: 90%;
width: 100%;
height: auto;

position: relative;
display: flex;
justify-self: center;
flex-direction: row;
justify-content: space-between;
p{
  color: #60BF94;
  font-size: 23px;
font-weight: 700;
margin: 1em 0 0.3em 0;
}

@media (min-width: 2560px) {
  max-width: 1440px;
}

@media (max-width: 1200px) {
  flex-direction: column;
}
@media (max-width: 600px) {
  flex-direction: column;
  padding-left:0;
  align-items: center;
  padding: 1em 0;
  p{
    font-size: 21px;
    justify-content: center;
    display: flex;
    text-align: center;

  }
}
`
const Left2=styled.div`
position: relative;
max-width: 700px;
width: 50%;
display: flex;
flex-direction: column;
padding: 0 0 15px ;
margin-right: 4em;
@media (max-width: 1200px) {
  width: 100%;
  max-width: fit-content;
  padding: 0  ;
  margin-right: 0em;

}
`
const Right2=styled.div`
position: relative;

width: 50%;
display: flex;
flex-direction: column;
.boxsuppliers{
  //height: auto;
  //margin-bottom: 3em;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  //justify-content: space-between;
  justify-content: space-around;
  gap: 70px;
  margin: 1em 0;
  //align-items: center;
  //overflow:hidden;
  //flex-direction: row;

  img{
  align-self: center;
  position: relative;
  //width: 200px;
  max-width: 200px;
  max-height: 82px;
  //height: fit-content;
  object-fit: cover;
  //aspect-ratio: auto;
  /*padding: 2px 2px;
  flex-shrink: 1;*/
  //margin: 24px 0px;
  
  }
}
/*@media (max-width: 1600px) {
  width: 50%;
  .boxsuppliers{
    justify-content: space-around;
    
    img{
    //  width: 21%;
     // margin: 2em 0;
 
  }
  }
}*/

@media (max-width: 1200px) {
  width: 100%;
  max-width: 100%;
  .boxsuppliers{
    width: 100%;

  }
}
@media (max-width: 700px) {
  width: 100%;
  .boxsuppliers{
    flex-direction: column;
    justify-content: center;
    gap: 70px;
    img{
      //margin: 2em 0;
 
  }
  }
}

/*@media (max-width: 380px) {
  .boxsuppliers{
    justify-content: center;
  }
}*/
`
const ThirdBlock=styled.div`
width: 100%;
display: flex;
justify-content: center;
position: relative;
margin: 1em 0 3em 0;

`
const ThirdBlockChild= styled.div`
max-width: 1440px;
width: 100%;
//padding-left: 45px;
display: flex;
flex-direction: column;
justify-self: center;
.desktop{
  display: flex;
    justify-content: center;
  img{
  //max-width: 1400px;
  width: 100%;
  justify-self: center;
  object-fit: cover;
}
}

.mobile{
  display: none;
}
p{
  position: relative;
  display: flex;
  background-color: #FFFFFF;
    color: #0044B0;
    font-size: 40px;
    font-weight: 700;
    font-style: italic;
    justify-self: center; 
    text-align: center;
    padding: 0 20%;
    margin: 1em 0;

}
.button {
      display: flex;
      justify-content: center;
      margin: 1em 0;
    }

@media (max-width: 900px) {
  .desktop{
    display:none
  }
  .mobile{
    display: flex;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    img{
  width: 100%;
object-fit: cover;}
  }
  p{
    padding: 0 10%;
  }
}

@media (max-width: 640px) {
  padding-left:0px;
  .desktop{
    display: none;
  }
  .mobile{
    display: flex;
    justify-content: center;
  }
  p{
    padding: 0 15px;
    font-size: 23px;
  }
}
`
const FourthBlock=styled.div`
width: 100%;
height: auto;
display: flex;
justify-content: center;
position: relative;
margin-bottom: 4em;
`

const ContainerVideo = styled.div`
width: 100%;
  position: relative;
  .frame-container {
    position: relative;
    padding-bottom: 40%; /* 16:9 60%*/  
    //padding-top: 25px;
    width: 100%; /* era 300% */
    //left: -100%; /* center */
    overflow: hidden;
}

.frame-container iframe {
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%;
}

.wrapper {
   overflow: hidden;
   max-width: 100%;
}
@media (min-width: 2560px) {
  max-width: 1440px;
}
  @media (max-width: 900px) {
    width: 100%;
    padding-bottom: 2rem;
   }
`

const NumericalCounter = styled.div`
  max-width: 700px;
  width: 100%;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 20px;
  margin-left: -30px;

  .content-data {
    width: auto;
    max-width: 28%;
    text-align: center;
    color: #FFFFFF;
    .dsc{
        padding-left: 0;
        font-weight: 500;

      }
    .numbersky-blue {
      font-family: "Montserrat";
      color: #FFFFFF;
      font-weight: 800;
      font-size: 46px;
      text-align: center;
    }

  }
  @media (max-width:1600px) {
    padding: 0 ;
    margin-top: 0;
    margin-left: 0px;
    width: 100%; max-width:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .content-data {
      margin-top: 1em;
      margin-bottom: 1em;
    .numbersky-blue {
      font-size: 40px;
      width:100%;
    }
    .dsc {
        font-size: 20px;
        width:100%;

      }
    }
  }

  @media (max-width: 600px) {
    padding: 0 ;
    margin-top: 0;
    margin-left: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .content-data {
      width: 100%;
      min-width: 200px;
      margin-bottom: 2em;
    .numbersky-blue {
      font-size: 40px;
      width:100%;
    }
    .dsc {
        font-size: 20px;
        width:100%;

      }
    }
  }

`
export const Galery = ({ sliderImages, counts,data,dataGeneral }) => {

  return (
    <Container>
      <SecondBlock>
       <SecondBlockChild>
       <Left2><p>Cambiando el rumbo al éxito</p>
       <NumericalCounter>  
        {counts?.map((element, index) => (
          <div key={index} className="content-data">
            <div className="numbersky-blue">{element?.number}</div>
            <div className="dsc">
              {element?.text}
            </div>
          </div>
        ))}

        {/* <div className="content-data">
          {data.counts.map(element => (
            <Count key={element.id} data={element} />
          ))}
        </div> */}
      </NumericalCounter>
      </Left2>

       <Right2>
      <p>Avalados por:  </p>
      <div className="boxsuppliers">
        {sliderImages?.map(slider => (
            <img src={slider?.image} alt="ITCAD empresa dedicada a brindar cursos de infraworks y brindamos certificación autocad"/>
            ))}
            {/*<img src="images\AboutUs\nos1.png"/>
            <img src="images\AboutUs\nos1.png"/> */}
      </div>
      </Right2>
      </SecondBlockChild> 
      </SecondBlock>

      <ThirdBlock>
        <ThirdBlockChild>
          <div className="desktop"><img src={data?.banner_image}/></div>
          <div className="mobile"><img src={data?.banner_mobile_image}/></div>

          <p>{data?.main_phrase}</p>
          <div className="button">
          <a href={dataGeneral} target="_blank">
            <Button02>Empieza hoy</Button02>
          </a>
        </div>
        </ThirdBlockChild>
      </ThirdBlock>

      <FourthBlock>
        {data?.show_main_video &&
      <ContainerVideo>
      <div class="wrapper"> 
      <div class="frame-container">
   <iframe width="100%" height="auto" src={data?.main_video_link}  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
   </div>
          </div>     
      </ContainerVideo>}
      </FourthBlock>
      </Container>
  )
}

{/*

const Container = styled.div`
  width: 100%;
  max-width: 80rem;
  margin: 4.125rem auto 0;
  padding: 0 3.125em;
  margin-top: 3.25em;
  margin-bottom: 7.1875em;
  .imagen {
    width: 100%;
    height: auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    .ant-carousel {
      width: 800px;
     
    }
  }
  img {
    width: 100%;
   
  }
  @media (max-width: 900px) {
    .imagen {
      .ant-carousel {
        width: 100%;
      }
    }
  }
  @media (max-width: 600px) {
    .imagen {
      .ant-carousel {
        width: 100%;
      }
    }
    img {
      width: 100%;
    }
  }
  @media (max-width: 400px) {
    .imagen {
      .ant-carousel {
        
      }
    }
    img {
      
    }
  }
`
export const Galery = ({ sliderImages }) => {
  return (

      <div className="imagen">
      
          {sliderImages?.map(slider => (
            <div>
              <img src={slider?.image} alt="ITCAD empresa dedicada a brindar cursos de infraworks y brindamos certificación autocad"/>
            </div>
          ))}
      
      </div>
  
  )
}
*/}