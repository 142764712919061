import * as React from "react"
import styled from "styled-components"

const Container = styled.div`
  /* padding: 0 0 0 3.125em; */
  height: 33.25rem;
  background: url(${p => p.banner}) center;

  /* background: url("/images/Hero4/fon-nos 1.png") center; */
  background-size: cover;
  background-position: cover;

  @media (max-width:900px){
  height: 12rem;
}
`
const ContainerInfo = styled.div`
  width: 90%;
  height: 100%;
  padding: 0 3.125em;
  max-width: 80rem;
  margin: 0 auto;
  display: flex;

  div {
    width: 50%;
    margin-top: 5rem;
    span {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 3em;
      line-height: 60px;

      color: #FFFFFF;
    }
  }
  @media (max-width: 900px) {
    div {
      width: 100%;
      margin-top: 30px;
    }
  }
`
export const Hero4 = ({ backgroundImage, title }) => {
  return (
    <>
      <Container banner={backgroundImage}>
        <ContainerInfo>
          <div>
          <span>{title}</span>
          </div>
        </ContainerInfo>
      </Container>
    </>
  )
}
