import React from "react"
import styled from "styled-components"
import { Paragraph } from "../Paragraph"
import { Title } from "../Title"
import { Adjectives } from "./Adjectives"
import { Items} from "../Items.js"

const Container = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  justify-content: center;
  display: flex;
  flex-direction: column;

  /*
  @media (max-width: 900px) {
    flex-direction: column;
    .text,
    .image {
      width: 100%;
    }
    .image {
      img {
        width: 100%;
      }
    }
  }*/
`
const FirstBlock=styled.div`
  position: relative;
  width: 100%;
  background-color: #FFFFFF;
  display:flex;
  justify-content: center;
`
const FirstBlockChild= styled.div`
width: 100%;
position: relative;
display: flex;
justify-self: center;
flex-direction: column;
margin: 4em 0;
padding-left: 80px;

.title{
  font-size: 38px;
    margin-bottom: 0.5em;
    color: #292929;
    display: flex;
    justify-self: flex-start;
    position: relative;
    width: 70%;

  }
  .body1{
  display: flex;
  flex-direction: row;

}

@media (min-width: 2560px) {
  max-width: 1440px;
}

@media (max-width: 1400px) {
  padding: 0 5%;
  .body1{
    flex-direction: column;

  }
}

@media (max-width: 1200px) {

.title{
  width: 100%;

}
}

@media (max-width: 500px) {
  margin: 2em 0 ;
  padding: 0 30px;
  .title{
    font-size: 24px;
  }
  .body1{
    flex-direction: column;
  }
}
`
const Left1=styled.div`
position: relative;
width: 100%;
display: flex;
flex-direction: column;
padding: 0 24px 0 0;
justify-content: space-around;

p{
  font-weight: 700;
  padding-top: 1em;
}
.text{
  margin-top: 24px;
  font-weight: 400;
  font-size: 18px;
  line-height: 170%;
  text-align: justify;
  padding: 0 50px 0 0;
}
.circles{
width:100% ;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;

}
.image1{
  margin: 2em 0;
  object-fit: scale-down;
  max-width: 600px;
  width: 100%;
  position: relative;
}
@media (max-width: 1400px) {
  padding: 0 0px 0 0;
  .text{
  font-size: 16px;}

}

@media (max-width: 900px) {
   padding: 0 0px 0 0;
  .text{
    line-height: 150%;
    font-size: 16px;
    margin-top: 0px;
    padding: 0 10px 0 0;
  }
  .circles{}

}

@media (max-width: 500px) {
  padding: 0 0px 0 0;
  width: 100%;
  .text{
    margin-top: 5px;
    font-size: 14px;
    text-align: justify;
    padding: 0 ;
  }
  .circles{
    justify-content: space-around;
  }
}
`
const Right1=styled.div`
position: relative;
width: 100%;
display: flex;
flex-direction: column;
justify-self: right;
padding: 0 0 0 40px;


.image2{
  width: 100%;
  max-height: auto;
  position: relative;
  img{
    object-fit: cover;

  }
}
.imgdetail{
  font-style: italic;
  justify-self:left;
  font-size: 18px;
  color: #888888;
  margin-top: 5px;

}

@media (max-width: 1400px) {
  width: 100%;
  margin-top: 2em;
  padding: 0 ;

}

@media (max-width: 500px) {
  width: 100%;
  padding: 0 ;

  .image2{
    width: 100%;
  }
  .imgdetail{
    font-size: 15px;
  }}
`

export const AboutUs = ({ contentTitle, content, data, data2}) => {

  const paragraphs = content ? content.split("/") : [];

  return (
    <Container>
      <FirstBlock>
      <FirstBlockChild>
        <Title className="title">{contentTitle}</Title>
        <div className="body1">

          <Left1>
            <div className="text">{paragraphs.map((paragraph, index) => (
            <div key={index}>{paragraph}</div>
            ))}</div>
            <p>En ITCAD te inspiramos a ser:</p>

            <div className="circles">
            {data2?.map(element => (
            <Adjectives key={element?.id} cantidad={data2.length}>
              {element?.text}
            </Adjectives>
            ))}
            </div>
          </Left1>

           <Right1>
             <img className="image2" src={data?.content_image} alt="empresa dedicada a enseñar cursos de revit, bim y cursos de modelador BIM"/>
             <div className="imgdetail">{data?.content_image_description}</div> 
           </Right1>
        </div>

      </FirstBlockChild>
      </FirstBlock>

      {/*<SecondBlock>
       <SecondBlockChild>
<Left2><p>Cambiando el rumbo al éxito</p></Left2>
<Right2><p>Avalados por:  </p></Right2>
       </SecondBlockChild> 
  </SecondBlock>*/}

    </Container>
  )
}
