import styled from "styled-components"

export const Title = styled.div`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.3em;
  font-feature-settings: 'clig' off, 'liga' off;
  max-width: 100%;

 /* @media (max-width: 100%) {
    /* font-size: 38px; */
 /* }
  @media (min-width: 1700px) {
  } */
`
